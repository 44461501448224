import React from "react";

// @ts-expect-error TS(7016): Could not find a declaration file for module 'styl... Remove this comment to see the full error message
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import {
  Title,
  Button,
  Section,
  Box,
  Text,
  InputGroup,
} from "../../components/Core";
import { device } from "../../utils";


// @ts-expect-error TS(2307): Cannot find module '../../assets/image/jpeg/l2-cta... Remove this comment to see the full error message
import imgCtaBG from "../../assets/image/jpeg/l2-cta-big.jpg";

// @ts-expect-error TS(2307): Cannot find module '../../assets/image/png/l2-cta-... Remove this comment to see the full error message
import imgOval from "../../assets/image/png/l2-cta-oval.png";

// @ts-expect-error TS(2307): Cannot find module '../../assets/image/jpeg/author... Remove this comment to see the full error message
import imgAuthor from "../../assets/image/jpeg/author.jpg";

const ImageBottom = styled(Box)`
  border: 10px solid transparent;
  box-shadow: ${({
  theme
}: any) => `0 52px 54px ${theme.colors.shadow}`};
  position: absolute;
  top: 21%;
  right: 0;
  z-index: 2;
  border-radius: 500px;
  max-width: 50%;
  overflow: hidden;

  @media ${device.md} {
    max-width: 100%;
    right: 15%;
  }
`;
const Author = styled(Box)`
  min-width: 271px;
  max-width: 271px;
  min-height: 271px;
  max-height: 271px;
  border-radius: 500px;
  overflow: hidden;
  margin-bottom: 30px;
  @media ${device.lg} {
    margin-bottom: 0;
  }
`;

const CTA = () => (
  <>
    <Section bg="holzDarkGreen">
      <Container>
        <Row className="align-items-center">
          <Col lg={6} md={10} className="">
            <div className="position-relative mb-5">
              <div
                className="img-1"
                data-aos="fade-down"
                data-aos-duration="750"
                data-aos-once="true"
              >
                <Author>
                  <img
                    src={imgAuthor}
                    alt=""
                    className="img-fluid"

                    // @ts-expect-error TS(2322): Type '{ src: any; alt: string; className: string; ... Remove this comment to see the full error message
                    css={`
                      border-radius: 10px;
                    `}
                  />
                </Author>
              </div>
              {/* <ImageBottom
                className=""
                bg="light"
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-once="true"
                data-aos-delay="500"
              >
                <img src={imgOval} alt="" className="img-fluid" />
              </ImageBottom> */}
            </div>
          </Col>
          <Col
            lg={6}
            md={9}
            className=""
            data-aos="fade-right"
            data-aos-duration="750"
            data-aos-once="true"
          >
            <div className="">
              <Title color="light">Get started now</Title>
              <Text color="light" opacity={0.7} mb={4}>
                Create custom landing pages with Omega that converts more
                visitors than any website. With lots of unique blocks, you can
                easily build a page without coding.
              </Text>
              <div className="pr-sm-5">
                <Button width="100%" mt={3}>
                  Get Started
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default CTA;
